import authenticationService from '@/services/authenticationService'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import axios from 'axios'

export default {
  mixins: [
    commonMixin,
    constantMixin,
    errorMixin
  ],
  data () {
    return {
      authenticationMixin: {
        checkedLogin: false,
        cookie: {
          name: 'VerstappenNVPublicLogin',
          timespan: '1Y'
        },
        header: 'verst-public-authorization',
        key: 'VerstappenNVPublicAuthentication'
      }
    }
  },
  computed: {
    AuthenticationMixin_LoggedIn () {
      return this.$store.state.authentication != null
    }
  },
  created () {
    authenticationService.Init(this)
    this.AuthenticationMixin_LoadSessionStorageAuthentication()
  },
  methods: {
    async AuthenticationMixin_CheckIfLoggedIn () {
      await authenticationService.CheckIfLoggedIn()
      this.authenticationMixin.checkedLogin = true
    },
    AuthenticationMixin_GetCurrentWerknemer () {
      return {
        familienaam: this.$store.state.authentication.familienaam,
        naam: this.$store.state.authentication.naam,
        naamOmgekeerd: this.$store.state.authentication.naamOmgekeerd,
        voornaam: this.$store.state.authentication.voornaam
      }
    },
    AuthenticationMixin_HasRights () {
      return this.AuthenticationMixin_LoggedIn
    },
    AuthenticationMixin_LoadSessionStorageAuthentication () {
      this.$store.state.authentication = JSON.parse(sessionStorage.getItem(this.authenticationMixin.key))

      if (this.$store.state.authentication) {
        this.$store.state.loggedIn = true
        this.AuthenticationMixin_SetHeader(this.authenticationMixin.header, this.$store.state.authentication.token)
      } else {
        this.$store.state.loggedIn = false
      }
    },
    async AuthenticationMixin_Login (account, remember = false) {
      if (account) {
        this.CommonMixin_Load(this.$options.name, true)
        const resp = await authenticationService.Login(account)

        if (resp) {
          if (remember) {
            this.$cookie.set(this.authenticationMixin.cookie.name, account.login, { expires: this.authenticationMixin.cookie.timespan })
          } else {
            this.$cookie.delete(this.authenticationMixin.cookie.name)
          }

          this.$store.state.authentication = resp.data
          sessionStorage.setItem(this.authenticationMixin.key, JSON.stringify(this.$store.state.authentication))
          this.AuthenticationMixin_SetHeader(this.authenticationMixin.header, resp.data.token)
          this.CommonMixin_Load(this.$options.name, false)
          location.reload()
        } else {
          this.CommonMixin_Load(this.$options.name, false)
        }
      }
    },
    async AuthenticationMixin_Logout () {
      this.CommonMixin_Load(this.$options.name, true)
      this.$store.state.authentication = null
      this.$store.state.loggedIn = false
      this.AuthenticationMixin_SetHeader(this.authenticationMixin.header, null)
      sessionStorage.clear(this.authenticationMixin.key)
      this.CommonMixin_Load(this.$options.name, false)

      const redirectLocation = '/'

      if (this.$router.currentRoute.path !== redirectLocation) {
        await this.$router.push(redirectLocation)
      }

      location.reload()
    },
    AuthenticationMixin_SetHeader (headerName, value) {
      axios.defaults.headers.common[headerName] = value
    }
  }
}
