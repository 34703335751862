// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import App from './App.vue'
import Vue from 'vue'
import Emitter from 'tiny-emitter'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookie from 'vue-cookie'
import VueMask from 'v-mask'
import './globals'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'

Vue.config.productionTip = false
Vue.prototype.$msalInstance = {}
Vue.prototype.$emitter = new Emitter()
Vue.use(VueMask)
Vue.use(VueCookie)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
