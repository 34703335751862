<template>
  <v-row
    no-gutters
  >
    <v-col
      :class="stacked ? '' : 'd-flex'"
    >
      <template
        v-for="(item, index) in data"
      >
        <div
          class="d-flex"
          :key="index"
          :style="`width: ${item.number / Max * 100.00}% !important`"
        >
          <v-progress-linear
            v-if="item.number > 0"
            :class="animated ? 'animated' : ''"
            :color="item.color ? item.color : 'grey'"
            :height="height"
            :striped="striped"
            value="100"
          >
            <template
              v-slot:default
            >
              <v-tooltip
                :left="stacked"
                :top="!stacked"
              >
                <template
                  v-slot:activator="{ on, attrs }"
                >
                  <div
                    v-bind="attrs"
                    v-on="showTooltip ? on : ''"
                    :class="stacked ? 'text-left' : 'text-center'"
                    style="overflow: hidden; width: calc(100% - 20px)"
                  >
                    <span
                      :style="`${item.textColor ? `color: ${item.textColor}` : ''}; max-width: 100%; white-space: nowrap; overflow: hidden;`"
                    >
                      {{ stacked && showTitle ? item.title : FormattedLabel(item) }}
                    </span>
                  </div>
                </template>
                <span
                  v-if="!item.tooltip"
                >
                  {{ FormattedLabel(item) }}
                </span>
                <span
                  v-if="item.tooltip"
                >
                  {{ item.tooltip }}
                </span>
              </v-tooltip>
            </template>
          </v-progress-linear>
          <v-tooltip
            :left="stacked"
            :top="!stacked"
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <div
                v-bind="attrs"
                v-on="showTooltip ? on : ''"
              >
                <div
                  v-if="stacked && item.number > 0"
                  class="ml-1"
                  style="white-space: nowrap;"
                >
                  {{ FormattedNumbers(item) }}
                </div>
                <div
                  v-else-if="stacked && item.number === 0 && showZeros"
                  class="pl-2"
                  style="border-left: 1px solid black; white-space: nowrap;"
                >
                  <span
                    class="mr-3"
                  >
                    {{ item.title }}
                  </span>
                  <span>
                    {{ FormattedNumbers(item) }}
                  </span>
                </div>
              </div>
            </template>
            <span
              v-if="!item.tooltip"
            >
              {{ FormattedLabel(item) }}
            </span>
            <span
              v-if="item.tooltip"
            >
              {{ item.tooltip }}
            </span>
          </v-tooltip>
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ControlGraphBar',
  props: {
    animated: {
      type: Boolean,
      default: false
    },
    data: Array,
    height: {
      type: Number,
      default: 25
    },
    max: {
      type: Number,
      default: 100
    },
    numberDecimals: {
      type: Number,
      default: 0
    },
    percentageDecimals: {
      type: Number,
      default: 0
    },
    showNumber: {
      type: Boolean,
      default: true
    },
    showPercentage: {
      type: Boolean,
      default: true
    },
    showTitle: Boolean,
    showTooltip: {
      type: Boolean,
      default: true
    },
    showZeros: {
      type: Boolean,
      default: true
    },
    stacked: Boolean,
    striped: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    Max () {
      return this.max > 0 ? this.max : 1
    }
  },
  methods: {
    FormatDecimal (value, digits = 2, showZero = true) {
      if (value || showZero) {
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'decimal',
          maximumFractionDigits: digits,
          minimumFractionDigits: digits
        })

        return formatter.format(value ?? 0)
      } else {
        return null
      }
    },
    FormattedLabel (item) {
      let label = ''

      if (this.showTitle) {
        label += item.title + ': '
      }

      label += this.FormattedNumbers(item)
      return label
    },
    FormattedNumber (number) {
      return this.FormatDecimal(number, this.numberDecimals)
    },
    FormattedNumbers (item) {
      let label = ''

      if (this.showPercentage && this.showNumber) {
        label += `${this.FormattedNumber(item.number)} (${this.FormattedPercentage(item.number)}%)`
      } else if (this.showPercentage) {
        label += `${this.FormattedPercentage(item.number)}%`
      } else if (this.showNumber) {
        label += this.FormattedNumber(item.number)
      }

      return label
    },
    FormattedPercentage (number) {
      return this.FormatDecimal(number / this.Max * 100.00, this.percentageDecimals)
    }
  }
}
</script>

<style>
.v-progress-linear.animated .v-progress-linear__determinate
{
    animation: move 10s linear infinite;
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 100px;
  }
}
</style>
