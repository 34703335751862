import axios from 'axios'

let _svc
let _that

export default {
  // Contact
  SendContactBericht: (contactBericht) => {
    try {
      return _svc.post('Klant/LoggedOut/Contact/Bericht/Send', contactBericht)
    } catch {
      return null
    }
  },

  // Enquete
  GetEnqueteListKlant: () => {
    try {
      return _svc.get('Klant/LoggedIn/Enquete/List/Klant')
    } catch {
      return null
    }
  },
  GetEnqueteListVraagGroep: (idKlant) => {
    try {
      return _svc.get(`Klant/LoggedIn/Enquete/List/VraagGroep/${idKlant}`)
    } catch {
      return null
    }
  },
  SaveEnquete: (enquete) => {
    try {
      return _svc.post('Klant/LoggedIn/Enquete/Save', enquete)
    } catch {
      return null
    }
  },

  // General
  GetDossier: () => {
    try {
      return _svc.get('Klant/Loggedin/Dossier')
    } catch {
      return null
    }
  },

  // NieuwsList
  GetNieuwsList: () => {
    try {
      return _svc.get('Klant/LoggedOut/Nieuws')
    } catch {
      return null
    }
  },

  // OverlijdensberichtList
  GetOverlijdensberichtList: () => {
    try {
      return _svc.get('Klant/LoggedOut/OverlijdensberichtList')
    } catch {
      return null
    }
  },
  GetOverlijdensberichtListListFiliaal: () => {
    try {
      return _svc.get('Klant/LoggedOut/OverlijdensberichtList/List/Filiaal')
    } catch {
      return null
    }
  },

  // OverlijdensberichtDetail
  GetOverlijdensberichtDetailKoffietafelListPost: () => {
    try {
      return _svc.get('Klant/LoggedOut/OverlijdensberichtDetail/Koffietafel/List/Post')
    } catch {
      return null
    }
  },
  GetOverlijdensberichtDetailLivestream: (idDossier, code) => {
    try {
      return _svc.get(`Klant/LoggedOut/OverlijdensberichtDetail/Livestream/Hyperlink/${idDossier}/${code}`)
    } catch {
      return null
    }
  },
  GetOverlijdensberichtDetailThumbnail: (id) => {
    try {
      return _svc.get(`Klant/LoggedOut/OverlijdensberichtDetail/Thumbnail/${id}`)
    } catch {
      return null
    }
  },
  SaveOverlijdensberichtDetailCondoleren: (overlijdensberichtDetailCondoleren) => {
    try {
      return _svc.post('Klant/LoggedOut/OverlijdensberichtDetail/Condoleren/Save', overlijdensberichtDetailCondoleren)
    } catch {
      return null
    }
  },
  SaveOverlijdensberichtDetailKoffietafel: (overlijdensberichtDetailKoffietafel) => {
    try {
      return _svc.post('Klant/LoggedOut/OverlijdensberichtDetail/Koffietafel/Save', overlijdensberichtDetailKoffietafel)
    } catch {
      return null
    }
  },

  // Uitvaart
  GetUitvaartCondoleanceList: () => {
    try {
      return _svc.get('Klant/LoggedIn/Uitvaart/CondoleanceList')
    } catch {
      return null
    }
  },
  GetUitvaartFacturatieList: () => {
    try {
      return _svc.get('Klant/LoggedIn/Uitvaart/FacturatieList')
    } catch {
      return null
    }
  },
  GetUitvaartKoffietafelInschrijvingList: () => {
    try {
      return _svc.get('Klant/LoggedIn/Uitvaart/KoffietafelInschrijvingList')
    } catch {
      return null
    }
  },
  GetUitvaartMuziekList: () => {
    try {
      return _svc.get('Klant/LoggedIn/Uitvaart/MuziekList')
    } catch {
      return null
    }
  },
  GetUitvaartOverlijdensberichtPublicatieList: () => {
    try {
      return _svc.get('Klant/LoggedIn/Uitvaart/OverlijdensberichtPublicatieList')
    } catch {
      return null
    }
  },
  SendUitvaartCondoleanceListAntwoord: (uitvaartCondoleanceAntwoord) => {
    try {
      return _svc.post('Klant/LoggedIn/Uitvaart/CondoleanceList/Antwoord', uitvaartCondoleanceAntwoord)
    } catch {
      return null
    }
  },

  // Webshop
  GetWebshopListCode: () => {
    try {
      return _svc.get('Klant/LoggedOut/Webshop/List/Code')
    } catch {
      return null
    }
  },
  GetWebshopListPost: () => {
    try {
      return _svc.get('Klant/LoggedOut/Webshop/List/Post')
    } catch {
      return null
    }
  },
  GetWebshopListProduct: (webshopType) => {
    try {
      return _svc.get(`Klant/LoggedOut/Webshop/List/Product/${webshopType}`)
    } catch {
      return null
    }
  },
  GetWebshopThumbnail: (id) => {
    try {
      return _svc.get(`Klant/LoggedOut/Webshop/Thumbnail/${id}`)
    } catch {
      return null
    }
  },
  SaveWebshopFacturatie: (webshopFacturatie) => {
    try {
      return _svc.post('Klant/LoggedOut/Webshop/Save', webshopFacturatie)
    } catch {
      return null
    }
  },

  // Other
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  }
}
