<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow"
    >
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        v-if="!AuthenticationMixin_LoggedIn"
        class="center my-8 py-8"
        cols="12"
        lg="4"
      >
        <h1
          class="text-center"
        >
          Vul onderstaande gegevens in om gebruik te maken van uw persoonlijke account.
        </h1>
        <v-row
          class="text-center"
          justify="center"
        >
          <v-col
            class="center"
            cols="12"
            lg="8"
          >
            <v-text-field
              v-model="account.login"
              :autofocus="!account.login"
              color="primary"
              label="Gebruikersnaam"
              outlined
              ref="login"
              @keypress.enter="Login()"
            />
            <v-text-field
              v-model="account.wachtwoord"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :autofocus="account.login && true"
              color="primary"
              label="Wachtwoord"
              outlined
              ref="wachtwoord"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              @keypress.enter="Login()"
            />
            <div
              class="centeredFlexbox"
            >
              <v-checkbox
                v-model="remember"
                color="primary"
                label="Onthoud mij"
              />
            </div>
            <v-btn
              class="mt-3"
              color="primary"
              tile
              @click="Login()"
            >
              Inloggen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'

export default {
  name: 'Login',
  mixins: [
    authenticationMixin,
    commonMixin,
    errorMixin
  ],
  data () {
    return {
      account: {
        login: null,
        wachtwoord: null
      },
      remember: true,
      showPassword: false
    }
  },
  mounted () {
    if (this.AuthenticationMixin_LoggedIn) {
      this.$router.push('Account')
    } else {
      this.$vuetify.goTo(0)
      this.account.login = this.$cookie.get(this.authenticationMixin.cookie.name)
    }
  },
  methods: {
    Login () {
      this.AuthenticationMixin_Login(this.account, this.remember)
    }
  }
}
</script>
