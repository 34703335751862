<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/${$options.name} banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <h1
          class="text-center"
        >
          <a
            class="noUnderline"
            title="Terug"
            @click="$router.push('ExtraDiensten')"
          >
            <v-icon
              class="iconBack iconPrimaryColor"
            >
              mdi-arrow-left-circle
            </v-icon>
          </a>
          Ik wil me financieel geen zorgen maken
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <p>
              Verstappen uitvaartzorg werkt samen met <a href="https://www.belfiusdirect.be/UKP/BO/?Language=NL&ref=VERSTAP_BF&name=Verstappen%20Uitvaartzorg%20%28Geel%29" target="_blank">Belfius Direct</a>, dat uitvaartverzekeringen aanbiedt.
            </p>
            <p>
              Met zo'n uitvaartverzekering regel je alle financiële beslommeringen die bij je overlijden kunnen rijzen.
              Dat geeft een bijzonder comfortabel gevoel.
              Het is eigenlijk een kwestie van zorgen voor jezelf én voor je naasten.
            </p>
            <p>
              Onze uitvaartverzekeringen zijn voor iedereen haalbaar.
              We bieden diverse formules aan, u kiest gewoon de beste voor uw situatie.
              De premie kan u maandelijks, jaarlijks of in één keer betalen.
              Ook dat beslist u zelf.
            </p>
            <p>
              De uitbetaling na uw overlijden gebeurt bijzonder snel.
              Een eventueel overblijvend saldo wordt aan uw nalatenschap toegevoegd.
            </p>
            <v-btn
              color="primary"
              tile
              to="Contact"
            >
              Neem contact op
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <h2
              class="pb-0 text-uppercase"
            >
              Plan een gesprek
            </h2>
            <p>
              Verstappen uitvaartzorg
              <br>
              Diestseweg 62
              <br>
              2440 Geel
            </p>
            <p>
              014 58 84 65
              <br>
              <a
                href="mailto:info@verstappen.be"
              >
                info@verstappen.be
              </a>
            </p>
            <v-btn
              color="primary"
              tile
              @click="$router.push('Afspraak')"
            >
              Maak een afspraak
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'

export default {
  name: 'Uitvaartverzekering',
  mixins: [
    commonMixin
  ],
  mounted () {
    this.$vuetify.goTo(0)
  }
}
</script>
