<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      justify="center"
    >
      <v-col
        class="center"
        cols="12"
        lg="8"
      >
        <h2
          class="colorPrimary text-center"
        >
          Muziek uitvaart
        </h2>
        <p
          class="text-center"
        >
          We voorzien vijf muziekstukken waarvan eentje bij aanvan en eentje op het einde.
          <br>
          U kan kiezen uit een uitgebreide collectie van muzieknummers,
          <br>
          maar indien gewenst kan u ook persoonlijk gekozen muziekfragmenten toevoegen.
          <br>
          Wijzigingen via uw persoonlijke account kunnen tot 24 uur voor de uitvaart.
        </p>
      </v-col>
      <v-col
        class="center mt-5"
        cols="12"
        lg="6"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="7"
          >
            <v-row>
              <v-col
                class="px-1"
                cols="12"
                lg="3"
              >
                <v-btn
                  block
                  class="mb-1"
                  :color="vocaal ? 'secondary' : '#C6C9CC'"
                  height="40"
                  tile
                  @click="vocaal = !vocaal"
                >
                  Vocaal
                </v-btn>
              </v-col>
              <v-col
                class="px-1"
                cols="12"
                lg="5"
              >
                <v-btn
                  block
                  class="mb-1"
                  :color="instrumentaal ? 'secondary' : '#C6C9CC'"
                  height="40"
                  tile
                  @click="instrumentaal = !instrumentaal"
                >
                  Instrumentaal
                </v-btn>
              </v-col>
              <v-col
                class="px-1"
                cols="12"
                lg="4"
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  clearable
                  :color="Muziek.length === 0 && search ? 'error' : 'primary'"
                  dense
                  label="Zoeken"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row
              v-if="current.idMuziek"
              justify="center"
            >
              <v-col
                class="text-center"
                cols="12"
                lg="12"
              >
                <control-music
                  :title="Title(current)"
                  :value="current.src"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="PageList.length === 0"
              justify="center"
            >
              <v-col
                class="font-italic my-8 py-8 text-center"
                cols="12"
                lg="8"
              >
                Er is momenteel geen muziek die binnen uw zoekcriteria valt.
              </v-col>
            </v-row>
            <v-pagination
              v-if="PageCount > 1"
              v-model="page"
              class="mt-3"
              color="primary"
              :length="PageCount"
            />
            <div
              class="my-8"
            >
              <v-row
                v-for="(lied, index) in PageList"
                class="my-1 py-0"
                :class="current === lied ? 'activeRow' : index % 2 === 0 ? 'alternatingContentRow2' : ''"
                :key="index"
              >
                <v-col
                  cols="12"
                  lg="12"
                >
                  <v-icon
                    class="clickAction mr-2"
                    color="black"
                    @click="SelectCurrent(lied)"
                  >
                    mdi-{{ current === lied ? 'volume-low' : 'play'}}
                  </v-icon>
                  {{ Title(lied) }}
                </v-col>
              </v-row>
            </div>
            <v-pagination
              v-if="PageCount > 1"
              v-model="page"
              color="primary"
              :length="PageCount"
            />
          </v-col>
          <v-col
            cols="12"
            lg="5"
          >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import blobService from '@/services/blobService'
import klantService from '@/services/klantService'
import ControlMusic from './ControlMusic.vue'

export default {
  name: 'Muziek',
  mixins: [
    authenticationMixin,
    commonMixin,
    constantMixin,
    errorMixin,
    listMixin
  ],
  components: {
    ControlMusic
  },
  data () {
    return {
      current: {},
      numberPerPage: 20,
      instrumentaal: true,
      page: 1,
      search: '',
      vocaal: true
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.samenstellen = true
    blobService.Init(this)
    klantService.Init(this)
    this.GetUitvaartMuziekList()
  },
  beforeDestroy () {
    this.$store.state.headers.samenstellen = false
  },
  watch: {
    PageCount (value) {
      if (this.page > value) {
        this.page = value
      }
    }
  },
  computed: {
    Muziek () {
      let list = this.listMixin.list

      if (!this.instrumentaal) {
        list = list.filter(x => x.muziekType !== this.constantMixin.codes.muziekType.values.instrumentaal)
      }

      if (!this.vocaal) {
        list = list.filter(x => x.muziekType !== this.constantMixin.codes.muziekType.values.vocaal)
      }

      if (this.search) {
        list = list.filter(x => x.artiest.toLowerCase().includes(this.search.toLowerCase()) || x.titel.toLowerCase().includes(this.search.toLowerCase()))
      }

      return list
    },
    PageList () {
      return this.CommonMixin_GetPageList(this.Muziek, this.page, this.numberPerPage)
    },
    PageCount () {
      return this.CommonMixin_GetPageCount(this.Muziek, this.numberPerPage)
    }
  },
  methods: {
    async GetBlobMuziek (idBlob) {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await blobService.GetBase64(idBlob)
      this.CommonMixin_Load(this.$options.name, false)

      if (resp && (resp.data || resp.data !== '')) {
        return this.CommonMixin_Clone(resp.data)
      } else {
        return null
      }
    },
    async GetUitvaartMuziekList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartMuziekList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = resp.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    async SelectCurrent (lied) {
      if (!lied.loaded) {
        if (lied.idBlobMuziek) {
          lied.src = (await this.GetBlobMuziek(lied.idBlobMuziek))?.blobDataString ?? lied.src
        }

        lied.loaded = true
      }

      this.current = this.current === lied ? {} : lied
    },
    Title (lied) {
      return `${lied.titel}${lied.titel && lied.artiest ? ' - ' : ''}${lied.artiest}`
    }
  }
}
</script>
