<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-form
      v-model="valid"
      ref="form"
    >
      <v-row
        class="alternatingContentRow pa-0"
      >
      </v-row>
      <v-row
        class="alternatingContentRow"
      >
        <v-col
          class="center my-8 py-8"
          cols="12"
          lg="6"
        >
          <h1
            class="text-center"
          >
            Mijn mening
          </h1>
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              lg="12"
            >
              <p
                class="text-center"
              >
                Wij stellen uw mening sterk op prijs.
                De informatie die wij van u ontvangen, stelt ons in de mogelijkheid om nog beter te begeleiden en ondersteunen bij een persoonlijke uitvaart.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <span
                class="font-weight-bold"
              >
                Dit is de mening van
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <v-autocomplete
                v-model="idEnqueteKlant"
                color="primary"
                dense
                item-value="idEnqueteKlant"
                item-text="naam"
                :items="Klanten"
                outlined
                @change="KlantSelected($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <span
                class="colorPrimary font-weight-bold"
              >
                Dit is de mening van
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="klantDetails.familienaam"
                color="primary"
                dense
                label="Uw naam"
                outlined
                ref="txtFamilienaam"
                :rules="[validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="klantDetails.voornaam"
                color="primary"
                dense
                label="Uw voornaam"
                outlined
                ref="txtVoornaam"
                :rules="[validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <v-text-field
                v-model="klantDetails.relatie"
                color="primary"
                dense
                label="Uw relatie tot de overledene"
                outlined
                ref="txtRelatie"
                :rules="[validationMixin.rules.required]"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-for="(vraagGroepItem, index) in vraagGroep"
        class="alternatingContentRow"
        :key="index"
      >
        <v-col
          class="center py-8"
          cols="12"
          lg="8"
        >
          <h2
            class="colorPrimary"
          >
            {{ vraagGroepItem.titel }}
          </h2>
          <v-row
            v-for="(vraag, index2) in vraagGroepItem.vragen"
            :key="`${index}-${index2}`"
          >
            <v-col
              class="pb-0"
              cols="12"
              lg="12"
            >
              {{ vraag.tekst }}
            </v-col>
            <v-col
              v-if="vraag.enqueteTypeVraag === constantMixin.codes.enqueteTypeVraag.values.radiobutton"
              class="pt-0"
            >
              <v-radio-group
                v-model="vraag.klantAntwoorden[0]"
              >
                <v-row
                  class="py-0"
                >
                  <v-col
                    v-for="(antwoord, index3) in vraag.antwoorden"
                    cols="12"
                    :key="`${index}-${index2}-${index3}`"
                    lg="2"
                  >
                    <v-radio
                      color="primary"
                      :label="antwoord.tekst"
                      :value="antwoord.idEnqueteAntwoord"
                    />
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-col
              v-if="vraag.enqueteTypeVraag === constantMixin.codes.enqueteTypeVraag.values.checkbox"
              class="pt-0"
            >
              <v-row
                class="py-0"
              >
                <v-col
                  v-for="(antwoord, index3) in vraag.antwoorden"
                  cols="12"
                  :key="`${index}-${index2}-${index3}`"
                  lg="2"
                >
                  <v-checkbox
                    v-model="vraag.klantAntwoorden[index3]"
                    color="primary"
                    :label="antwoord.tekst"
                    :value="antwoord.idEnqueteAntwoord"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-else-if="vraag.enqueteTypeVraag === constantMixin.codes.enqueteTypeVraag.values.langeTekst"
              class="pt-0"
              cols="12"
              lg="6"
            >
              <v-textarea
                v-model="vraag.klantAntwoorden[0]"
                auto-grow
                color="primary"
                dense
                outlined
              />
            </v-col>
            <v-col
              v-else-if="vraag.enqueteTypeVraag === constantMixin.codes.enqueteTypeVraag.values.korteTekst"
              class="pt-0"
              cols="12"
              lg="6"
            >
              <v-text-field
                v-model="vraag.klantAntwoorden[0]"
                color="primary"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row
            v-if="index + 1 === vraagGroep.length"
          >
            <v-col
              class="center my-1 py-1"
              cols="12"
              lg="12"
            >
              <v-btn
                class="float-right"
                color="primary"
                tile
                @click="SaveEnquete()"
              >
                Opslaan
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="showDialog"
      width="400px"
      persistent
    >
      <v-card
        class="pa-3"
      >
        <h2
          class="colorSecondary mt-3 text-center"
        >
          Uw mening werd opgeslagen
        </h2>
        <p
          class="colorSecondary text-center"
        >
          <v-btn
            class="mt-3"
            color="primary"
            tile
            @click="CloseDialog()"
          >
            OK
          </v-btn>
        </p>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import textMixin from '@/mixins/textMixin'
import validationMixin from '@/mixins/validationMixin'
import klantService from '@/services/klantService'

export default {
  name: 'Enquete',
  mixins: [
    authenticationMixin,
    commonMixin,
    constantMixin,
    errorMixin,
    textMixin,
    validationMixin
  ],
  data () {
    return {
      idEnqueteKlant: '00000000-0000-0000-0000-000000000000',
      klant: [],
      klantDetails: {
        familienaam: null,
        idEnqueteKlant: null,
        relatie: null,
        voornaam: null
      },
      showDialog: false,
      valid: false,
      vraagGroep: []
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_CheckIfLoggedIn()
    klantService.Init(this)
    this.GetEnqueteLists()
  },
  computed: {
    Klanten () {
      const klanten = this.CommonMixin_Clone(this.klant)

      klanten.unshift(
        this.CommonMixin_Clone({
          familienaam: null,
          idDossier: null,
          idEnqueteKlant: this.constantMixin.guids.empty,
          isNew: true,
          naam: 'Nieuw persoon',
          naamOmgekeerd: 'Nieuw persoon',
          relatie: null,
          voornaam: null
        })
      )

      return klanten
    },
    KlantAntwoorden () {
      const klantAntwoorden = []

      this.vraagGroep.forEach(vraagGroep => {
        vraagGroep.vragen.forEach(vraag => {
          const klantAntwoord = {
            idEnqueteKlant: this.klantDetails.idEnqueteKlant,
            idEnqueteVraag: vraag.idEnqueteVraag
          }

          if (vraag.enqueteTypeVraag === this.constantMixin.codes.enqueteTypeVraag.values.radiobutton) {
            if (vraag.klantAntwoorden[0]) {
              klantAntwoord.idEnqueteAntwoord = vraag.klantAntwoorden[0]
              klantAntwoorden.push(klantAntwoord)
            }
          } else if (vraag.enqueteTypeVraag === this.constantMixin.codes.enqueteTypeVraag.values.checkbox) {
            vraag.klantAntwoorden.forEach(antwoord => {
              if (antwoord) {
                const klantAntwoord2 = this.CommonMixin_Clone(klantAntwoord)
                klantAntwoord2.idEnqueteAntwoord = antwoord
                klantAntwoorden.push(klantAntwoord2)
              }
            })
          } else if (vraag.enqueteTypeVraag === this.constantMixin.codes.enqueteTypeVraag.values.langeTekst || vraag.enqueteTypeVraag === this.constantMixin.codes.enqueteTypeVraag.values.korteTekst) {
            if (vraag.klantAntwoorden[0]) {
              klantAntwoord.antwoord = vraag.klantAntwoorden[0]
              klantAntwoorden.push(klantAntwoord)
            }
          }
        })
      })

      return klantAntwoorden
    }
  },
  methods: {
    CloseDialog () {
      this.CommonMixin_Load(this.$options.name, true)
      this.GetEnqueteListKlant()
      this.CommonMixin_Load(this.$options.name, false)
      this.KlantSelected(null)
      this.KlantSelected(this.idEnqueteKlant)
      this.showDialog = false
      this.$vuetify.goTo(0)
    },
    async GetEnqueteListKlant () {
      const resp = await klantService.GetEnqueteListKlant()

      if (resp && (resp.data || resp.data !== '')) {
        this.klant = resp.data
      }
    },
    async GetEnqueteLists () {
      this.CommonMixin_Load(this.$options.name, true)
      await this.GetEnqueteListKlant()
      await this.GetEnqueteListVraagGroep()
      this.CommonMixin_Load(this.$options.name, false)
    },
    async GetEnqueteListVraagGroep () {
      const resp = await klantService.GetEnqueteListVraagGroep(this.idEnqueteKlant)

      if (resp && (resp.data || resp.data !== '')) {
        this.vraagGroep = resp.data
      }
    },
    KlantSelected (idEnqueteKlant) {
      this.klantDetails = this.Klanten.find(klant => klant.idEnqueteKlant === idEnqueteKlant)

      if (idEnqueteKlant) {
        this.GetEnqueteListVraagGroep()
      }
    },
    async SaveEnquete () {
      this.$refs.form.validate()

      if (this.valid) {
        const enquete = {
          klant: this.klantDetails,
          klantAntwoorden: this.KlantAntwoorden
        }

        const resp = await klantService.SaveEnquete(enquete)

        if (resp && (resp.data || resp.data !== '')) {
          this.showDialog = true
        } else {}
      } else {
        if (!this.klantDetails.familienaam) {
          this.$refs.txtFamilienaam.focus()
        } else if (!this.klantDetails.voornaam) {
          this.$refs.txtVoornaam.focus()
        } else if (!this.klantDetails.relatie) {
          this.$refs.txtRelatie.focus()
        }
      }
    }
  }
}
</script>
