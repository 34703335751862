import axios from 'axios'

let _svc
let _that

export default {
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  },
  Download: (id) => {
    try {
      return _svc.get(`Blob/Download/${id}`, {
        responseType: 'blob'
      })
    } catch {
      return null
    }
  },
  DownloadByBlobSearchCriteria: (blobSearchCriteria) => {
    try {
      return _svc.post('Blob/Download', blobSearchCriteria, {
        responseType: 'blob'
      })
    } catch (ex) {
      alert(ex)
      return null
    }
  },
  GetBase64: (id) => {
    try {
      return _svc.get(`Blob/Base64/${id}`)
    } catch {
      return null
    }
  }
}
