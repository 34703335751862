<template>
  <v-form
    v-model="valid"
    ref="form"
  >
    <v-row
      :id="idStepTitle"
      justify="center"
    >
      <v-col
        class="my-8 py-8"
        cols="12"
        lg="8"
      >
        <div
          v-if="StepCurrent"
        >
          <h2>
            Stap {{ Step + 1 }}: {{ StepCurrent.title }}
          </h2>
          <div
            v-if="StepCurrent.component === 'shop'"
          >
            <v-row>
              <v-col
                v-if="ShowFilterMateriaal"
                cols="12"
                lg="3"
                md="6"
              >
                <v-autocomplete
                  v-model="filters.materiaal"
                  color="primary"
                  dense
                  item-value="id"
                  item-text="text"
                  :items="FilterMateriaal"
                  label="Materiaal"
                  outlined
                />
              </v-col>
              <v-col
                v-if="ShowFilterPriceRange"
                cols="12"
                lg="6"
                md="6"
              >
                <v-range-slider
                  :value="PriceRange"
                  class="pt-1 px-3 rangeSlider"
                  :max="PriceRangeAbsolute[1]"
                  :min="PriceRangeAbsolute[0]"
                  @change="PriceRangeChange($event)"
                >
                  <template v-slot:prepend>
                    <span
                      class="mt-1"
                    >
                      {{ PriceRangeAbsolute[0] }}&nbsp;EUR
                    </span>
                  </template>
                  <template v-slot:append>
                    <span
                      class="mt-1"
                    >
                      {{ PriceRangeAbsolute[1] }}&nbsp;EUR
                    </span>
                  </template>
                  <template v-slot:thumb-label="props">
                    {{ props.value }}
                  </template>
                </v-range-slider>
              </v-col>
            </v-row>
            <v-row
              v-if="PageList.length === 0"
              justify="center"
            >
              <v-col
                class="font-italic my-8 py-8 text-center"
                cols="12"
                lg="8"
              >
                Er zijn momenteel geen producten die binnen uw zoekcriteria vallen.
              </v-col>
            </v-row>
            <v-row
              v-else
            >
              <v-col
                v-for="(product, index) in PageList"
                class="text-center pa-2"
                :class="data.idProduct === product.idProduct ? 'elementSelected' : ''"
                cols="12"
                :key="index"
                lg="3"
                sm="3"
              >
                <img
                  v-if="product.foto.loaded"
                  class="webshopFoto"
                  :src="product.foto.blobDataString ? product.foto.blobDataString : require(`@/assets/images/${$options.name} placeholder ${type}.jpg`)"
                />
                <br>
                <span
                  class="colorPrimary text-uppercase"
                >
                  {{ product.naamExtern ? product.naamExtern : product.naam }}
                  <br>
                  {{ FormatMixin_FormatDecimal(product.prijsInclusiefBtw) }}&nbsp;EUR
                </span>
                <br>
                <v-btn
                  block
                  class="mt-1"
                  color="primary"
                  :disabled="data.idProduct === product.idProduct"
                  tile
                  @click="ProductChoose(product)"
                >
                  Kies
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="PageCount > 1"
              justify="center"
            >
              <v-col
                class="my-8 py-8"
                cols="12"
                lg="8"
              >
                <v-pagination
                  v-model="filters.page"
                  color="primary"
                  :length="PageCount"
                />
              </v-col>
            </v-row>
            <v-row
              justify="center"
            >
              <v-btn
                class="my-3"
                color="primary"
                :disabled="!ListFiltered.some(x => x.idProduct === data.idProduct)"
                tile
                @click="StepNext()"
              >
                {{ StepCurrent.submit }}
              </v-btn>
            </v-row>
          </div>
          <div
            v-if="StepCurrent.component === 'ribbons'"
          >
            <v-row
              justify="center"
            >
              <v-col
                cols="12"
                lg="3"
              >
              </v-col>
              <v-col
                class="text-center pa-2"
                cols="12"
                lg="3"
              >
                <img
                  class="webshopFoto"
                  :src="SelectedProduct.foto.blobDataString ? SelectedProduct.foto.blobDataString : require(`@/assets/images/${$options.name} placeholder ${type}.jpg`)"
                />
                <br>
                <span
                  class="colorPrimary text-uppercase"
                >
                  {{ SelectedProduct.naamExtern ? SelectedProduct.naamExtern : SelectedProduct.naam }}
                  <br>
                  {{ FormatMixin_FormatDecimal(SelectedProduct.prijsInclusiefBtw) }}&nbsp;EUR
                </span>
              </v-col>
              <v-col
                cols="12"
                lg="5"
              >
              </v-col>
            </v-row>
            <v-row
              justify="center"
            >
              <v-col
                cols="12"
                lg="3"
              >
                <span
                  class="font-weight-bold"
                >
                  Tekst op lint 1
                </span>
              </v-col>
              <v-col
                cols="12"
                lg="8"
              >
                <v-text-field
                  v-model="data.lint1"
                  color="primary"
                  dense
                  label="bv. je geburen en vrienden"
                  outlined
                  :rules="[validationMixin.rules.max255Characters]"
                />
              </v-col>
              <v-col
                cols="12"
                lg="3"
              >
                <span
                  class="font-weight-bold"
                >
                  Tekst op lint 2 (optioneel)
                </span>
              </v-col>
              <v-col
                cols="12"
                lg="8"
              >
                <v-text-field
                  v-model="data.lint2"
                  color="primary"
                  dense
                  label="bv. je geburen en vrienden"
                  outlined
                  :rules="[validationMixin.rules.max255Characters]"
                />
              </v-col>
              <v-col
                cols="12"
                lg="3"
              >
              </v-col>
              <v-col
                cols="12"
                lg="8"
              >
                De bloemen worden door onze mensen op de uitvaartdienst bezorgd.
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <a
                class="mr-5"
                @click="StepPrevious()"
              >
                &lt; Vorige stap
              </a>
              <v-btn
                class="my-3"
                color="primary"
                tile
                @click="StepNext()"
              >
                {{ StepCurrent.submit }}
              </v-btn>
            </v-row>
          </div>
          <div
            v-if="StepCurrent.component === 'jewelry_options'"
          >
            <v-row
              justify="center"
            >
              <v-col
                cols="12"
                lg="3"
              >
              </v-col>
              <v-col
                class="text-center pa-2"
                cols="12"
                lg="3"
              >
                <img
                  class="webshopFoto"
                  :src="SelectedProduct.foto.blobDataString ? SelectedProduct.foto.blobDataString : require(`@/assets/images/${$options.name} placeholder ${type}.jpg`)"
                />
                <br>
                <span
                  class="colorPrimary text-uppercase"
                >
                  {{ SelectedProduct.naamExtern ? SelectedProduct.naamExtern : SelectedProduct.naam }}
                  <br>
                  {{ FormatMixin_FormatDecimal(SelectedProduct.prijsInclusiefBtw) }}&nbsp;EUR
                </span>
              </v-col>
              <v-col
                cols="12"
                lg="5"
              >
              </v-col>
            </v-row>
            <v-row
              justify="center"
            >
              <v-col
                cols="12"
                lg="3"
              >
                <span
                  class="font-weight-bold"
                >
                  Extra opties:
                </span>
              </v-col>
              <v-col
                cols="12"
                lg="8"
              >
                <v-checkbox
                  v-if="SelectedProduct.asMogelijk"
                  v-model="data.asToevoegen"
                  class="mt-0"
                  color="primary"
                  dense
                  label="As"
                  :rules="SelectedProduct.asVereist ? [validationMixin.rules.required] : []"
                />
                <v-checkbox
                  v-if="SelectedProduct.vingerafdrukMogelijk"
                  v-model="data.vingerafdrukToevoegen"
                  class="mt-0"
                  color="primary"
                  dense
                  label="Vingerafdruk"
                  :rules="SelectedProduct.vingerafdrukVereist ? [validationMixin.rules.required] : []"
                />
                <span
                  v-if="!SelectedProduct.asMogelijk && !SelectedProduct.vingerafdrukMogelijk"
                >
                  Geen extra opties beschikbaar.
                </span>
                <p
                  class="colorPrimary text-uppercase"
                >
                  Meerprijs: {{ FormatMixin_FormatDecimal(Meerprijs) }}&nbsp;EUR
                </p>
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <a
                class="mr-5"
                @click="StepPrevious()"
              >
                &lt; Vorige stap
              </a>
              <v-btn
                class="my-3"
                color="primary"
                tile
                @click="StepNext()"
              >
                {{ StepCurrent.submit }}
              </v-btn>
            </v-row>
          </div>
          <div
            v-else-if="StepCurrent.component === 'billing_information'"
          >
            <v-row
              justify="center"
            >
              <v-col
                class="text-center pa-2"
                cols="12"
                lg="5"
              >
                <control-select
                  v-if="betalen"
                  v-model="data.betalingsmethode"
                  dense
                  itemValue="value"
                  itemText="longDescription"
                  :items="lists.betalingsmethode"
                  label="Betalingsmethode"
                  outlined
                  required
                  :requiredText="textMixin.messages.required"
                />
                <v-checkbox
                  v-if="betalen && !IsPaymentMethod('achterafBetalen')"
                  v-model="firm"
                  class="mt-0"
                  color="primary"
                  dense
                  label="Ik bestel in naam van een firma"
                  :rules="RulesBillingInformationFactuurGewenst"
                />
                <v-checkbox
                  v-if="ShowFieldsBillingInformationFirm"
                  v-model="data.factuurGewenst"
                  class="mt-0"
                  color="primary"
                  dense
                  label="Ik wens een factuur"
                  :rules="RulesBillingInformationFactuurGewenst"
                />
                <v-text-field
                  v-model="data.facturatieEmail"
                  color="primary"
                  dense
                  label="Uw e-mailadres"
                  outlined
                  :rules="[validationMixin.rules.email, validationMixin.rules.max50Characters]"
                />
                <template
                  v-if="betalen"
                >
                  <v-text-field
                    v-if="ShowFieldsBillingInformationFirm"
                    v-model="data.facturatieFirmanaam"
                    color="primary"
                    dense
                    label="Firmanaam"
                    outlined
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="data.facturatieFamilienaam"
                    color="primary"
                    dense
                    label="Naam"
                    outlined
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="data.facturatieVoornaam"
                    color="primary"
                    dense
                    label="Voornaam"
                    outlined
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-row>
                    <v-col
                      cols="12"
                      lg="9"
                    >
                      <v-text-field
                        v-model="data.facturatieStraat"
                        color="primary"
                        dense
                        :label="`Straat${ShowFieldsBillingInformationFirm ? ' (firma)' : ''}`"
                        outlined
                        :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="3"
                    >
                      <v-text-field
                        v-model="data.facturatieHuisnummer"
                        color="primary"
                        dense
                        :label="`Huisnummer${ShowFieldsBillingInformationFirm ? ' (firma)' : ''}`"
                        outlined
                        :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                      />
                    </v-col>
                  </v-row>
                  <v-autocomplete
                    v-model="data.idPostFacturatie"
                    color="primary"
                    dense
                    item-value="idPost"
                    item-text="tekst"
                    :items="lists.post"
                    :label="`Gemeente${ShowFieldsBillingInformationFirm ? ' (firma)' : ''}`"
                    outlined
                    :rules="[validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="data.facturatieBtwNummer"
                    color="primary"
                    dense
                    label="BTW-nummer"
                    outlined
                    :rules="RulesBillingInformationFacturatieBtwNummer"
                  />
                </template>
                <template
                  v-else
                >
                  <v-text-field
                    v-model="data.facturatieFamilienaam"
                    color="primary"
                    dense
                    label="Naam"
                    outlined
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="data.facturatieVoornaam"
                    color="primary"
                    dense
                    label="Voornaam"
                    outlined
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                </template>
                <vue-recaptcha
                  v-if="reCaptchaKey && betalen"
                  :sitekey="reCaptchaKey"
                  @error="robot = false"
                  @expired="robot = false"
                  @render="robot = false"
                  @verify="robot = true"
                />
              </v-col>
              <v-col
                cols="12"
                lg="2"
              >
              </v-col>
              <v-col
                cols="12"
                lg="5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <img
                      class="webshopFoto"
                      :src="SelectedProduct.foto.blobDataString ? SelectedProduct.foto.blobDataString : require(`@/assets/images/${$options.name} placeholder ${type}.jpg`)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="8"
                  >
                    <p>
                      <span
                        class="colorPrimary font-weight-bold text-uppercase "
                      >
                        {{ SelectedProduct.naamExtern ? SelectedProduct.naamExtern : SelectedProduct.naam }}
                      </span>
                      <br>
                      <span
                        class="colorPrimary text-uppercase"
                      >
                        {{ FormatMixin_FormatDecimal(SelectedProduct.prijsInclusiefBtw) }}&nbsp;EUR
                      </span>
                      <template
                        v-if="this.type === 'juwelen'"
                      >
                        <br>
                        <span
                          class="colorPrimary font-weight-bold text-uppercase"
                        >
                          Extra opties
                        </span>
                        <br>
                        <span
                          class="colorPrimary "
                        >
                        {{ FormatMixin_FormatDecimal(Meerprijs) }}&nbsp;EUR
                        </span>
                      </template>
                      <template
                        v-if="this.type === 'bloemstukken'"
                      >
                        <br>
                        <span
                          class="colorPrimary font-weight-bold "
                        >
                          Tekst op lint 1
                        </span>
                        <br>
                        <span
                          class="colorPrimary "
                        >
                          {{ data.lint1 }}
                        </span>
                        <br>
                        <template
                          v-if="data.lint2"
                        >
                          <span
                            class="colorPrimary font-weight-bold "
                          >
                            Tekst op lint 2
                          </span>
                          <br>
                          <span
                            class="colorPrimary "
                          >
                            {{ data.lint2 }}
                          </span>
                        </template>
                      </template>
                    </p>
                  </v-col>
                </v-row>
                <hr>
                <v-row
                  class="mt-3"
                >
                  <v-col
                    class="colorPrimary"
                    cols="12"
                    lg="6"
                  >
                    Totaal incl. BTW
                  </v-col>
                  <v-col
                    class="colorPrimary text-right"
                    cols="12"
                    lg="6"
                  >
                    {{ FormatMixin_FormatDecimal(SelectedProduct.prijsInclusiefBtw + (Meerprijs ? Meerprijs : 0)) }}&nbsp;EUR
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <a
                class="mr-5"
                @click="StepPrevious()"
              >
                &lt; Vorige stap
              </a>
              <v-btn
                class="my-3"
                color="primary"
                tile
                @click="StepNext()"
              >
                {{ StepCurrent.submit }}
              </v-btn>
            </v-row>
          </div>
          <div
            v-else-if="StepCurrent.component === 'payment_create'"
          >
            <template
              v-if="!betalen || IsPaymentMethod('achterafBetalen')"
            >
              Even geduld, uw bestelling wordt verwerkt.
            </template>
            <template
              v-if="IsPaymentMethod('onlineBetaling')"
            >
              Even geduld, u wordt nu doorverbonden met een betaalprovider.
            </template>
          </div>
          <div
            v-else-if="StepCurrent.component === 'payment_check'"
          >
            <div>
              Even geduld, de status van uw betaling wordt opgehaald.
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import validationMixin from '@/mixins/validationMixin'
import configurationService from '@/services/configurationService'
import klantService from '@/services/klantService'
import paymentService from '@/services/paymentService'
import ControlSelect from './ControlSelect.vue'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'Webshop',
  mixins: [
    commonMixin,
    constantMixin,
    errorMixin,
    validationMixin
  ],
  components: {
    ControlSelect,
    VueRecaptcha
  },
  props: {
    betalen: {
      default: true,
      required: false,
      type: Boolean
    },
    dossier: {
      required: true,
      type: Object
    },
    idBetalingOnline: {
      required: false,
      type: String
    },
    type: {
      required: true,
      type: String,
      validator: function (value) {
        return ['bloemstukken', 'juwelen', 'miniUrne', 'urne'].includes(value)
      }
    }
  },
  data () {
    return {
      data: {
        idProduct: null
      },
      filters: {
        materiaal: '',
        numberPerPage: 12,
        page: 1,
        priceRange: [null, null]
      },
      firm: false,
      idStepTitle: 'stepTitle',
      lists: {
        betalingsmethode: [],
        code: [],
        post: [],
        product: []
      },
      reCaptchaKey: null,
      robot: false,
      step: 0,
      steps: {
        bloemstukken: [
          {
            component: 'shop',
            submit: 'Bevestig bloemstuk',
            title: 'Selecteer een bloemstuk'
          },
          {
            component: 'ribbons',
            submit: 'Vul gegevens in',
            title: 'Kies tekst de op linten'
          },
          {
            component: 'billing_information',
            robot: true,
            submit: 'Bestel bloemstuk',
            title: 'Vul uw gegevens in'
          },
          {
            component: 'payment_create',
            title: 'Bestelling'
          },
          {
            component: 'payment_check',
            status: null,
            success: null,
            title: 'Betaling'
          }
        ],
        juwelen: [
          {
            component: 'shop',
            submit: 'Bevestig juweel',
            title: 'Selecteer een juweel'
          },
          {
            component: 'jewelry_options',
            submit: 'Vul gegevens in',
            title: 'Kies extra opties voor juweel'
          },
          {
            component: 'billing_information',
            robot: true,
            submit: 'Bestel juweel',
            title: 'Vul uw gegevens in'
          },
          {
            component: 'payment_create',
            title: 'Bestelling'
          },
          {
            component: 'payment_check',
            status: null,
            success: null,
            title: 'Betaling'
          }
        ],
        miniUrne: [
          {
            component: 'shop',
            submit: 'Bevestig mini-urne',
            title: 'Selecteer een mini-urne'
          },
          {
            component: 'billing_information',
            robot: true,
            submit: 'Bestel mini-urne',
            title: 'Vul uw gegevens in'
          },
          {
            component: 'payment_create',
            title: 'Bestelling'
          },
          {
            component: 'payment_check',
            status: null,
            success: null,
            title: 'Betaling'
          }
        ],
        urne: [
          {
            component: 'shop',
            submit: 'Bevestig urne',
            title: 'Selecteer een urne'
          },
          {
            component: 'billing_information',
            robot: true,
            submit: 'Bestel urne',
            title: 'Vul uw gegevens in'
          },
          {
            component: 'payment_create',
            title: 'Bestelling'
          },
          {
            component: 'payment_check',
            status: null,
            success: null,
            title: 'Betaling'
          }
        ]
      },
      valid: false
    }
  },
  computed: {
    FilterMateriaal () {
      return [
        {
          text: 'Alle',
          id: ''
        },
        ...Object.values(this.constantMixin.productGroepen[this.type]).filter(x => x.text)
      ]
    },
    ListBase () {
      switch (this.type) {
        case 'bloemstukken':
          return this.ListBaseBloemstukken
        default:
          return this.lists.product
      }
    },
    ListBaseBloemstukken () {
      let list = this.lists.product

      if (this.dossier) {
        if (this.dossier.kinderbloemen) {
          list = list.filter(x => x.idProductType === this.constantMixin.productGroepen[this.type].kinderbloemen.id)

          if (this.dossier.deadline.bloemenKinder && new Date(this.dossier.deadline.BloemenKinder).getTime() < new Date().getTime()) {
            list = list.filter(x => x.idProductType !== this.constantMixin.productGroepen[this.type].kinderbloemen.id)
          }
        } else if (this.dossier.boeketten) {
          list = list.filter(x => x.idProductType === this.constantMixin.productGroepen[this.type].boeketten.id)

          if (this.dossier.deadline.bloemenBoeket && new Date(this.dossier.deadline.bloemenBoeket).getTime() < new Date().getTime()) {
            list = list.filter(x => x.idProductType !== this.constantMixin.productGroepen[this.type].boeketten.id)
          }
        } else {
          list = list.filter(x => x.idProductType !== this.constantMixin.productGroepen[this.type].kinderbloemen.id)

          this.FilterMateriaal.forEach(materiaal => {
            if (materiaal.deadline && this.dossier.deadline[materiaal.deadline] && new Date(this.dossier.deadline[materiaal.deadline]).getTime() < new Date().getTime()) {
              list = list.filter(x => x.idProductType !== materiaal.id)
            }
          })
        }
      }

      return list
    },
    ListFiltered () {
      let list = this.ListBase
      list = this.FilterListByPrice(list)
      list = this.FilterListByMaterial(list)
      return list
    },
    Meerprijs () {
      if (this.SelectedProduct) {
        let meerprijs = 0

        if (this.data.asToevoegen && this.data.vingerafdrukToevoegen && this.SelectedProduct.combinatieMeerprijs) {
          meerprijs += this.SelectedProduct.combinatieMeerprijs
        } else if (this.data.asToevoegen && this.SelectedProduct.asMeerprijs) {
          meerprijs += this.SelectedProduct.asMeerprijs
        } else if (this.data.vingerafdrukToevoegen && this.SelectedProduct.vingerafdrukMeerprijs) {
          meerprijs += this.SelectedProduct.vingerafdrukMeerprijs
        }

        return meerprijs
      } else {
        return 0
      }
    },
    PageList () {
      return this.CommonMixin_GetPageList(this.ListFiltered, this.filters.page, this.filters.numberPerPage)
    },
    PageCount () {
      return this.CommonMixin_GetPageCount(this.ListFiltered, this.filters.numberPerPage)
    },
    PriceRange () {
      if (this.lists.product.length > 0) {
        const absoluteMin = this.PriceRangeAbsolute[0]
        const absoluteMax = this.PriceRangeAbsolute[1]
        const selectedMin = this.filters.priceRange[0] ?? absoluteMin
        const selectedMax = this.filters.priceRange[1] ?? absoluteMax
        const priceMin = selectedMin < absoluteMin ? absoluteMin : selectedMin > absoluteMax ? absoluteMax : selectedMin
        const priceMax = selectedMax > absoluteMax ? absoluteMax : selectedMax < absoluteMin ? absoluteMin : selectedMax
        const min = isNaN(priceMin) ? 0 : priceMin
        const max = isNaN(priceMax) ? 0 : priceMax
        return [min, max]
      } else {
        return [0, 0]
      }
    },
    PriceRangeAbsolute () {
      if (this.lists.product.length > 0) {
        const prijzen = this.ListBase.map(x => x.prijsInclusiefBtw)
        const priceMin = Math.floor(Math.min(...prijzen))
        const priceMax = Math.ceil(Math.max(...prijzen))
        const min = isFinite(priceMin) ? priceMin : 0
        const max = isFinite(priceMax) ? priceMax : 0
        return [min, max]
      } else {
        return [0, 0]
      }
    },
    Robot () {
      return !this.StepCurrent.robot || !this.reCaptchaKey || this.robot || !this.betalen
    },
    RulesBillingInformationFacturatieBtwNummer () {
      const rules = [this.validationMixin.rules.max50Characters, this.validationMixin.rules.vatNumber]

      if (this.ShowFieldsBillingInformationFirm) {
        rules.push(this.validationMixin.rules.required)
      }

      return rules
    },
    RulesBillingInformationFactuurGewenst () {
      return this.IsPaymentMethod('achterafBetalen') ? [this.validationMixin.rules.required] : []
    },
    SelectedProduct () {
      return this.ListFiltered.find(x => x.idProduct === this.data.idProduct)
    },
    ShowFieldsBillingInformationFirm () {
      return this.IsPaymentMethod('achterafBetalen') || this.firm
    },
    ShowFilterMateriaal () {
      return !(this.type === 'bloemstukken' && (this.dossier.kinderbloemen || this.dossier.boeketten))
    },
    ShowFilterPriceRange () {
      return this.PriceRangeAbsolute[0] !== this.PriceRangeAbsolute[1]
    },
    Step () {
      return this.idBetalingOnline ? this.steps[this.type].findIndex(x => x.component === 'payment_check') : this.step
    },
    StepCurrent () {
      return this.steps[this.type][this.Step]
    },
    WebshopType () {
      return this.constantMixin.codes.webshopType.values[this.type]
    }
  },
  watch: {
    'data.betalingsmethode' (value) {
      this.data.factuurGewenst = this.IsPaymentMethod('achterafBetalen', value)
    },
    'filters.page' () {
      this.GoToElement(this.idStepTitle)
    },
    PageCount (value) {
      if (this.filters.page > value) {
        this.filters.page = value
      }
    },
    PageList (value) {
      this.LoadPictures(value)
    },
    async Step () {
      await this.StepChanged()
    }
  },
  async mounted () {
    configurationService.Init(this)
    klantService.Init(this)
    paymentService.Init(this)
    await this.GetConfiguration()
    await this.GetListAll()
    await this.StepChanged()
  },
  methods: {
    EmitSuccess (message, succeeded = true) {
      this.$emit('success', {
        message: message,
        succeeded: succeeded
      })
    },
    FilterListByMaterial (list) {
      return this.filters.materiaal ? list.filter(x => x.idProductType === this.filters.materiaal) : list
    },
    FilterListByPrice (list) {
      return list.filter(x => x.prijsInclusiefBtw >= this.PriceRange[0] && x.prijsInclusiefBtw <= this.PriceRange[1])
    },
    async GetBlobFoto (idBlob) {
      const resp = await klantService.GetWebshopThumbnail(idBlob)

      if (resp && (resp.data || resp.data !== '')) {
        return this.CommonMixin_Clone(resp.data)
      } else {
        return null
      }
    },
    async GetConfiguration () {
      const resp = await configurationService.GetReCaptchaSiteKey()

      if (resp && (resp.data || resp.data !== '')) {
        this.reCaptchaKey = resp.data
      }
    },
    async GetListAll () {
      this.CommonMixin_Load(this.$options.name, true)
      await this.GetListCode()
      await this.GetListPost()
      await this.GetListProduct()
      this.CommonMixin_Load(this.$options.name, false)
    },
    async GetListCode () {
      const resp = await klantService.GetWebshopListCode()

      if (resp?.data) {
        this.lists.code = resp.data
        this.lists.betalingsmethode = this.CommonMixin_GetElementsByPropertyValue(resp.data, 'type', this.constantMixin.codes.betalingsmethode.name)
      }
    },
    async GetListPost () {
      const resp = await klantService.GetWebshopListPost()

      if (resp && (resp.data || resp.data !== '')) {
        this.lists.post = resp.data
      }
    },
    async GetListProduct () {
      const resp = await klantService.GetWebshopListProduct(this.WebshopType)
      if (resp && (resp.data || resp.data !== '')) {
        this.lists.product = resp.data
      }
    },
    GoToElement (idElement) {
      const element = document.getElementById(idElement)

      if (element) {
        const coordinate = element.getBoundingClientRect().top + window.pageYOffset - document.getElementById('header').getBoundingClientRect().height - document.getElementById('header2').getBoundingClientRect().height
        this.$vuetify.goTo(coordinate)
      }
    },
    IsPaymentMethod (methode, value = this.data.betalingsmethode) {
      return value === this.constantMixin.codes.betalingsmethode.values[methode]
    },
    async LoadPictures (list) {
      this.CommonMixin_Load(this.$options.name, true)

      await list.forEach(async item => {
        if (item.foto && !item.foto.loaded) {
          if (item.foto.idBlob && !item.foto.loaded) {
            if (!item.foto?.blobDataString) {
              item.foto = await this.GetBlobFoto(item.foto.idBlob) ?? item.foto
              item.foto.loaded = true
            }
          } else {
            item.foto.loaded = true
          }
        }
      })

      this.$forceUpdate()
      this.CommonMixin_Load(this.$options.name, false)
    },
    async PaymentCheck () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await paymentService.CheckPayment(this.$route.query.idBetalingOnline)
      let repeat = false

      if (resp && (resp.data || resp.data !== '')) {
        const pendingStatuses = ['open', 'pending', 'authorized']
        repeat = pendingStatuses.includes(resp.data)
        this.StepCurrent.status = resp.data

        if (repeat) {
          setTimeout(async () => await this.PaymentCheck(), 10000)
        } else {
          const message = resp.data === 'paid' ? 'Uw betaling werd goed ontvangen.' : 'Er is iets misgegaan bij het ontvangen van uw betaling. Probeer opnieuw of neem contact op met ons.'
          this.EmitSuccess(message)
        }
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    async PaymentCreate () {
      const resp = await paymentService.CreatePayment({
        ...this.data,
        idDossier: this.dossier.idDossier
      })

      if (resp && (resp.data || resp.data !== '')) {
        window.location.href = resp.data
      }
    },
    PriceRangeChange (range) {
      this.filters.priceRange = range
      this.$forceUpdate()
    },
    ProductChoose (product) {
      this.data.idProduct = product.idProduct
      this.$forceUpdate()
      this.StepNext()
    },
    async Save () {
      this.$refs.form.validate()

      if (this.valid) {
        this.$refs.form.validate()

        if (this.Robot) {
          this.CommonMixin_Load(this.$options.name, true)
          const resp = await klantService.SaveWebshopFacturatie({
            ...this.data,
            idDossier: this.dossier.idDossier,
            typeFacturatie: this.constantMixin.codes.typeFacturatie.values[this.betalen ? 'factuurAanDerden' : 'factuurDossier'],
            webshopType: this.WebshopType
          })

          if (resp && (resp.data || resp.data !== '')) {
            this.EmitSuccess('Uw bestelling werd doorgegeven.')
          }

          this.CommonMixin_Load(this.$options.name, false)
        } else {
          this.CommonMixin_Snackbar(this.textMixin.messages.captcha, 'error')
        }
      }
    },
    async StepChanged () {
      this.GoToElement(this.idStepTitle)
      if (this.StepCurrent) {
        if (this.StepCurrent.component === 'payment_create') {
          if (!this.betalen || this.IsPaymentMethod('achterafBetalen')) {
            await this.Save()
          } else if (this.IsPaymentMethod('onlineBetaling')) {
            await this.PaymentCreate()
          }
        } else if (this.StepCurrent.component === 'payment_check') {
          await this.PaymentCheck(this.$route.query.idMollieCall)
        }
      }
    },
    StepNext () {
      this.$refs.form.validate()

      if (this.valid) {
        if (this.Step + 1 < this.steps[this.type].length && this.Robot) {
          this.GoToElement(this.idStepTitle)
          this.step++
        } else {
          this.CommonMixin_Snackbar(this.textMixin.messages.captcha, 'error')
        }
      }
    },
    StepPrevious () {
      this.GoToElement(this.idStepTitle)
      this.step--
    }
  }
}
</script>
