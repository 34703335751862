<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        v-if="AuthenticationMixin_LoggedIn"
        class="center my-8 py-8"
        cols="12"
        lg="4"
      >
        <h1
          class="text-center"
        >
          Uitloggen
        </h1>
        <v-row
          class="text-center"
          justify="center"
        >
          <v-col
            class="center"
            cols="12"
            lg="8"
          >
            U wordt uitgelogd.
            Enkele ogenblikken geduld alstublieft.
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'

export default {
  name: 'Logout',
  mixins: [
    authenticationMixin,
    commonMixin
  ],
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_Logout()
  }
}
</script>
