<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      v-if="ShowList"
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/${name} banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="ShowList"
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="10"
      >
        <h1
          class="text-center"
        >
          De {{ locationMixin[name.toLowerCase()].naam }} van Verstappen
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="9"
          >
            <p
              class="text-center"
              v-html="locationMixin[name.toLowerCase()].tekst"
            />
          </v-col>
        </v-row>
        <v-row
          class="my-8 py-8"
          justify="center"
        >
          <v-col
            cols="12"
            lg="10"
          >
            <locatie-list
              :name="name"
              @locatie="ShowDetail($event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        v-if="!ShowList && Locatie"
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/${name} ${Locatie.locatie} banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        v-if="!ShowList && Locatie"
        class="center my-8 py-8"
        cols="12"
        lg="10"
      >
        <h1
          class="clear text-center"
        >
          <a
            class="noUnderline"
            title="Terug"
            @click="ShowDetail(null)"
          >
            <v-icon
              class="iconBack iconPrimaryColor"
            >
              mdi-arrow-left-circle
            </v-icon>
          </a>
          {{ Locatie[name.toLowerCase()].type }} {{ Locatie.locatie }}
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <p
              v-for="(paragraaf, index) in Locatie[name.toLowerCase()].tekst"
              :key="index"
            >
              <span v-html="paragraaf" />
            </p>
            <v-btn
              class="mb-3 mt-10"
              color="primary"
              tile
              to="Contact"
            >
              Maak een afspraak
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="2"
          >
            <div
              class="pl-10"
            >
              <h2
                class="pb-0 text-uppercase"
              >
                Praktisch
              </h2>
              <p>
                <span
                  v-html="Locatie.adres.naam"
                />
                <br>
                <span v-html="Locatie.adres.straat" /> <span v-html="Locatie.adres.huisnummer" />
                <br>
                <span v-html="Locatie.adres.postcode" /> <span v-html="Locatie.adres.gemeente" />
                <br>
                <span
                  v-html="Locatie.contact.telefoonnummer"
                />
                <br>
                <a
                  :href="`mailto:${Locatie.contact.emailadres}`"
                >
                  <span
                    v-html="Locatie.contact.emailadres"
                />
                </a>
              </p>
              <p
                v-if="Locatie[name.toLowerCase()].openingsuren"
              >
                <span
                  v-for="(openingsuur, index) in Locatie[name.toLowerCase()].openingsuren"
                  :key="index"
                >
                  <span
                    v-html="openingsuur"
                  />
                  <br>
                </span>
              </p>
              <h2
                class="pb-0 text-uppercase"
              >
                Parking
              </h2>
              <p>
                <span
                  v-for="(parking, index) in Locatie[name.toLowerCase()].parking"
                  :key="index"
                >
                  <span
                    v-html="parking"
                  />
                  <br>
                </span>
              </p>
              <h2
                class="pb-0 text-uppercase"
              >
                Overzicht
              </h2>
              <p>
                <ul>
                  <li
                    v-for="(item, index) in Locatie[name.toLowerCase()].overzicht"
                    :key="index"
                  >
                    <a
                      v-if="item === 'Aula'"
                      class="text-decoration-underline"
                      @click="$router.push(`Aulas?locatie=${Locatie.locatie}`)"
                    >
                      Aula
                    </a>
                    <span
                      v-else
                      v-html="item"
                    />
                  </li>
                </ul>
              </p>
              <figure
                v-if="Locatie[name.toLowerCase()].foto && false"
              >
                <v-img
                  :src="require(`@/assets/images/Funerarium ${Locatie.adres.gemeente} extra.jpg`)"
                />
                <figcaption
                  class="font-italic mt-3 text-caption"
                  v-html="Locatie[name.toLowerCase()].foto.onderschrift"
                />
              </figure>
            </div>
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            class="text-center"
            cols="12"
            lg="8"
          >
            <div
              v-if="Locatie[name.toLowerCase()].gallerij"
            >
              <div
                v-if="Locatie[name.toLowerCase()].gallerij.menuItems.length > 1"
              >
                <a
                  v-for="(item, index) in Locatie[name.toLowerCase()].gallerij.menuItems"
                  class="mx-5"
                  :key="index"
                  :class="gallerij == item.toLowerCase() ? 'text-decoration-underline' : ''"
                  @click="gallerij = item.toLowerCase()"
                >{{ item }}</a>
              </div>
              <v-row
                v-if="gallerij"
                class="mt-5"
              >
                <v-col
                  v-for="column in 3"
                  cols="12"
                  :key="column"
                  lg="4"
                  md="4"
                  style="padding-left: 6px; padding-right: 5px;"
                >
                  <v-img
                    v-for="(item, index) in Locatie[name.toLowerCase()].gallerij[gallerij][`col${column}`]"
                    contain
                    :key="index"
                    max-height="554px"
                    max-width="350px"
                    :src="require(`@/assets/images/${name} ${Locatie.locatie} foto ${item}.jpg`)"
                    style="margin-bottom: 11px;"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'
import locationMixin from '@/mixins/locationMixin'
import LocatieList from './LocatieList.vue'

export default {
  name: 'Locaties',
  mixins: [
    commonMixin,
    locationMixin
  ],
  components: {
    LocatieList
  },
  props: {
    name: String
  },
  data () {
    return {
      gallerij: null,
      locatie: null
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
    this.GetLocatieFromUrl()
  },
  watch: {
    async '$route.params' () {
      this.GetLocatieFromUrl()
      this.$vuetify.goTo(0)
    },
    locatie () {
      this.gallerij = this.locatie && this.Locatie[this.name.toLowerCase()].gallerij ? this.Locatie[this.name.toLowerCase()].gallerij.menuItems[0].toLowerCase() : null
    }
  },
  computed: {
    Locatie () {
      return this.locationMixin.locaties.find(locatie => { return locatie.locatie === this.locatie })
    },
    ShowList () {
      return !this.locatie || this.locatie.trim() === ''
    }
  },
  methods: {
    GetLocatieFromUrl () {
      if (this.$route.query != null) {
        const locatie = this.$route.query.locatie

        if (locatie && locatie !== '') {
          this.locatie = locatie
        } else {
          this.locatie = null
        }
      } else {
        this.locatie = null
      }
    },
    ShowDetail (locatie) {
      this.locatie = locatie
      this.$vuetify.goTo(0)
    }
  }
}
</script>
