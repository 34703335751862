<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow"
    >
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 pt-8"
        cols="12"
        lg="6"
      >
        <h2
          class="text-center"
        >
          Maatregelen COVID-19
        </h2>
        <p>
          In het kader van de voorzorgsmaatregelen rond COVID-19 en met het oog op ieders goede gezondheid, dienen we ons steeds te houden aan de op dat moment geldende voorschriften.
          Op datum van vandaag betekent dit het volgende:
        </p>
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-2"
        cols="12"
        lg="6"
      >
        <ul
          class="triangleList"
        >
          <li>
            Een uitvaart wordt momenteel beperkt tot maximum 15 personen.
          </li>
          <li>
            Om de gezamenlijke tijd in een gesloten ruimte zo beperkt mogelijk te houden, zullen de deuren pas een kwartier voor aanvang van de uitvaart geopend worden.
          </li>
          <li>
            Bij het betreden van het gebouw vragen we om de handen te ontsmetten en een mondmasker te dragen.
            We vragen om zoveel mogelijk eigen mondmaskers te voorzien.
            Voor diegenen die er geen bij hebben, zal een wegwerpexemplaar ter beschikking gesteld worden.
          </li>
          <li>
            We vragen dat iedereen meteen plaatsneemt in de aula en hierbij rekening houdt met een afstand van 1,5m.
            Mensen uit eenzelfde bubbel kunnen eventueel wel dichter bij elkaar zitten.
          </li>
          <li>
            Bij afloop van de uitvaart en na het groeten van de kist of urne, vragen we dat iedereen meteen het gebouw verlaat.
            Er zal geen formeel moment voorzien worden waarbij men de familie kan condoleren.
          </li>
          <li>
            Er kan momenteel geen koffietafel plaatsvinden.
          </li>
        </ul>
        <p>
          Wij volgen de wijzigingen op de voet en passen ons aan wanneer de voorschriften wijzigen.
        </p>
        <p>
          Voor verdere vragen of opmerkingen, mag u ons steeds contacteren.
          <br>
          We maken er samen met u een mooie uitvaart van.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'

export default {
  name: 'COVID19',
  mixins: [
    authenticationMixin
  ],
  beforeDestroy () {
    this.$store.state.headers.raadplegen = false
  },
  mounted () {
    this.$vuetify.goTo(0)
    this.$store.state.headers.raadplegen = this.AuthenticationMixin_LoggedIn
  }
}
</script>
