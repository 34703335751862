<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row>
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="10"
      >
        <h1
          class="text-center"
        >
          Uitvaart
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <p
              class="text-center"
            >
              U kan hier de online condoleances lezen, aanwezigheden op de koffietafel raadplegen en eventueel de uitvaart herbekijken.
              We begeleiden u graag stap voor stap bij het samenstellen van een unieke uitvaart.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="center"
        cols="12"
        lg="8"
      >
        <v-row
          class="pb-0"
          justify="center"
        >
          <v-col
            class="colorSecondary font-weight-bold"
            cols="12"
            lg="10"
          >
            Raadplegen
          </v-col>
        </v-row>
        <v-row
          class="pl-3"
          justify="center"
          style="padding-right: 1px;"
        >
          <v-col
            cols="12"
            lg="10"
            style="border-bottom: 2px solid #424E58"
          >
          </v-col>
        </v-row>
        <v-row
          v-for="row in Math.ceil(raadplegen.items.length / 5)"
          class="px-0"
          :class="row === Math.ceil(raadplegen.items.length / 5) ? '' : 'pb-0'"
          justify="center"
          :key="row"
        >
          <v-col
            v-for="(item, index) in raadplegen.items.slice(5 * (row - 1), 5 * row)"
            class="pr-0"
            cols="12"
            :key="index"
            lg="2"
          >
            <v-btn
              v-if="item.title"
              block
              class="btnUitvaartOverzicht mb-1 text-center"
              color="secondary"
              tile
              x-large
              :to="item.to"
            >
              <span
                v-html="item.title"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="mb-8 pb-8"
    >
      <v-col
        class="center"
        cols="12"
        lg="8"
      >
        <v-row
          class="mt-2 pb-0"
          justify="center"
        >
          <v-col
            class="colorInvullen font-weight-bold"
            cols="12"
            lg="2"
          >
            Samenstellen
          </v-col>
          <v-col
            cols="12"
            lg="2"
          >
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            lg="2"
          >
            <v-btn
              v-if="false"
              class="arrowSmall btnUitvaartOverzicht ml-1"
              color="#DEC067"
              tile
              x-small
            />
            <span
              v-if="false"
              class="arrowSmallText ml-1"
            >
              In/aanvullen mogelijk
            </span>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            lg="2"
          >
            <v-btn
              v-if="false"
              class="arrowSmall btnUitvaartOverzicht ml-1"
              color="#695413"
              tile
              x-small
            />
            <span
              v-if="false"
              class="arrowSmallText ml-1"
            >
              Controle/goedkeuring vereist
            </span>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            lg="2"
          >
            <v-btn
              v-if="false"
              class="arrowSmall btnUitvaartOverzicht ml-1"
              color="#638964"
              tile
              x-small
            />
            <span
              v-if="false"
              class="arrowSmallText ml-1"
            >
              Goedgekeurd
            </span>
          </v-col>
        </v-row>
        <v-row
          class="pl-3"
          justify="center"
          style="padding-right: 1px;"
        >
          <v-col
            class="pr-1"
            cols="12"
            lg="10"
            style="border-bottom: 2px solid #DEC067"
          >
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
          </v-col>
        </v-row>
        <v-row
          v-for="row in Math.ceil(samenstellen.items.length / 5)"
          class="px-0"
          :class="row === Math.ceil(samenstellen.items.length / 5) ? '' : 'pb-0'"
          justify="center"
          :key="row"
        >
          <v-col
            v-for="(item, index) in samenstellen.items.slice(5 * (row - 1), 5 * row)"
            class="pr-0"
            cols="12"
            :key="index"
            lg="2"
          >
            <v-btn
              v-if="item.title"
              block
              class="arrow btnUitvaartOverzicht mb-3 text-center"
              color="#DEC067"
              tile
              x-large
              :to="item.to"
            >
              <span
                v-html="item.title"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'

export default {
  name: 'Uitvaart',
  mixins: [
    authenticationMixin
  ],
  data () {
    return {
      raadplegen: {
        items: [
          {
            title: 'Condoleances',
            to: 'Condoleances'
          },
          {
            title: 'Aanwezigen<br>koffietafel',
            to: 'Koffietafel'
          },
          {
            title: 'Kostenoverzicht',
            to: 'Facturatie'
          },
          {
            title: '', // 'Video<br>uitvaart',
            to: 'Video'
          },
          {
            title: '', // 'Maatregelen<br>COVID-19',
            to: 'COVID19'
          }
        ]
      },
      samenstellen: {
        items: [
          // {
          //   title: 'Draaiboek<br>uitvaart',
          //   to: 'Draaiboek'
          // },
          {
            title: 'Rouwdrukwerk',
            to: 'Rouwdrukwerk'
          },
          {
            title: 'Muziek<br>uitvaart',
            to: 'Muziek'
          },
          {
            title: 'Publicaties',
            to: 'Publicaties'
          },
          {
            title: '', // 'Teksten<br>uitvaart',
            to: 'Teksten'
          },
          {
            title: '', // 'Foto\'s<br>uitvaart',
            to: 'Fotos'
          },
          {
            title: 'Urne',
            to: 'Urne'
          },
          {
            title: 'Bloemen',
            to: 'Bloemen'
          },
          {
            title: 'Mini-urne',
            to: 'MiniUrne'
          },
          {
            title: 'Herinnerings-<br>juwelen',
            to: 'Herinneringsjuwelen'
          },
          {
            title: '', // 'Symboliek',
            to: 'Symboliek'
          },
          {
            title: '', // 'Graf-<br>monumenten',
            to: 'Grafmonumenten'
          }
        ]
      }
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_CheckIfLoggedIn()
  }
}
</script>
