<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row>
      <v-col
        class="center pt-8"
        cols="12"
        lg="12"
      >
        <v-row
          justify="center"
        >
          <v-col
            class="text-center"
            cols="12"
            lg="8"
          >
            <h2
              class="text-center"
            >
              Kostenoverzicht
            </h2>
          </v-col>
        </v-row>
        <v-row
          class="alternatingContentRow2 font-weight-bold mt-2 px-3 py-0"
          justify="center"
          style="border-bottom: 1px solid #424E58; margin-bottom: -8px"
        >
          <v-col
            class="py-2"
            cols="12"
            lg="4"
          >
            <v-row>
              <v-col
                cols="12"
                lg="3"
              >
                Productgroep
              </v-col>
              <v-col
                cols="12"
                lg="1"
              />
              <v-col
                cols="12"
                lg="3"
              >
                Producttype
              </v-col>
              <v-col
                cols="12"
                lg="1"
              />
              <v-col
                cols="12"
                lg="3"
              >
                Product
              </v-col>
            </v-row>
            <v-row
              class="font-italic pt-0"
            >
              <v-col
                cols="12"
                lg="12"
              >
                Omschrijving
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="py-2"
            cols="12"
            lg="6"
          >
            <v-row>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                Aantal
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                Eenheidsprijs
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                Totaal excl. BTW
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                BTW
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                BTW-bedrag
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                Totaal incl. BTW
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-for="row in listMixin.list.length"
          class="mt-2 pa-3"
          :class="row % 2 === 0 ? 'alternatingContentRow2' : ''"
          justify="center"
          :key="row"
        >
          <v-col
            v-if="listMixin.list[row - 1]"
            class="py-0"
            cols="12"
            lg="4"
          >
            <v-row>
              <v-col
                cols="12"
                lg="3"
              >
                {{ listMixin.list[row - 1].product.productType.productGroep.naam }}
              </v-col>
              <v-col
                cols="12"
                lg="1"
              />
              <v-col
                cols="12"
                lg="3"
              >
                {{ listMixin.list[row - 1].product.productType.naam }}
              </v-col>
              <v-col
                cols="12"
                lg="1"
              />
              <v-col
                cols="12"
                lg="3"
              >
                {{ listMixin.list[row - 1].product.naamExtern ? listMixin.list[row - 1].product.naamExtern : listMixin.list[row - 1].product.naam }}
              </v-col>
            </v-row>
            <v-row
              v-if="listMixin.list[row - 1].omschrijving"
              class="font-italic pt-0"
            >
              <v-col
                cols="12"
                lg="12"
              >
                {{ listMixin.list[row - 1].omschrijving }}
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="listMixin.list[row - 1]"
            class="py-0"
            cols="12"
            lg="6"
          >
            <v-row>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                {{ listMixin.list[row - 1].aantal ? `${listMixin.list[row - 1].aantal}x` : '' }}
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                {{ listMixin.list[row - 1].eenheidsprijs == null ? '' : FormatMixin_FormatDecimal(listMixin.list[row - 1].eenheidsprijs, 2, true, '', '&nbsp;EUR') }}
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                {{ FormatMixin_FormatDecimal(listMixin.list[row - 1].bedragExclusiefBtw, 2, false, '', '&nbsp;EUR') }}
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                {{ listMixin.list[row - 1].btwPercentageCode && listMixin.list[row - 1].btwPercentageCode.longDescription && !isNaN(listMixin.list[row - 1].btwPercentageCode.longDescription.replace(',', '.')) && FormatMixin_FormatDecimal(parseFloat(listMixin.list[row - 1].btwPercentageCode.longDescription), 2, false) ? `${FormatMixin_FormatDecimal(parseFloat(listMixin.list[row - 1].btwPercentageCode.longDescription), 2, false)}%` : null }}
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                {{ FormatMixin_FormatDecimal(listMixin.list[row - 1].bedragBtw, 2, false, '', '&nbsp;EUR') }}
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                {{ listMixin.list[row - 1].bedragInclusiefBtw == null ? '' : FormatMixin_FormatDecimal(listMixin.list[row - 1].bedragInclusiefBtw, 2, true, '', '&nbsp;EUR') }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          class="font-weight-bold mt-3 pa-0"
          :class="listMixin.list.length % 2 === 0 ? '' : 'alternatingContentRow2'"
          justify="center"
          style="border-top: 1px solid #424E58;"
        >
          <v-col
            class="py-2"
            cols="12"
            lg="4"
          >
          </v-col>
          <v-col
            class="py-2"
            cols="12"
            lg="6"
          >
            <v-row>
              <v-col
                class="text-right"
                cols="12"
                lg="10"
              >
              </v-col>
              <v-col
                class="text-right"
                cols="12"
                lg="2"
              >
                {{ FormatMixin_FormatDecimal(TotaalInclusiefBTW, 2, false, '', '&nbsp;EUR') }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'
import formatMixin from '@/mixins/formatMixin'
import listMixin from '@/mixins/listMixin'
import klantService from '@/services/klantService'

export default {
  name: 'Facturatie',
  mixins: [
    authenticationMixin,
    commonMixin,
    errorMixin,
    formatMixin,
    listMixin
  ],
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.raadplegen = true
    klantService.Init(this)
    this.GetUitvaartFacturatieList()
  },
  beforeDestroy () {
    this.$store.state.headers.raadplegen = false
  },
  computed: {
    TotaalInclusiefBTW () {
      const prijzen = this.listMixin.list.length ? this.listMixin.list.map(x => x.bedragInclusiefBtw) : [0]
      return prijzen.reduce((x, y) => x + y) ?? 0
    }
  },
  methods: {
    async GetUitvaartFacturatieList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartFacturatieList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = resp.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  }
}
</script>
