<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
    </v-row>
    <v-row
      class="alternatingContentRow my-8 py-8"
    >
      <v-col
        class="center"
        cols="12"
        lg="10"
      >
        <h2
          class="colorPrimary text-center"
        >
          Rouwdrukwerk
        </h2>
        <p
          class="text-center"
        >
          Alle belangrijke documenten voor de rouwbrief op één plaats.
          <br>
          Hiervoor heeft u Acrobat Reader nodig.
          U kan dit gratis <a class="secondaryLink" href="https://get.adobe.com/nl/reader/" target="_blank">downloaden</a>.
        </p>
      </v-col>
      <v-col
        class="center mt-5"
        cols="12"
        lg="6"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="4"
          >
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <p>
              <v-btn
                block
                color="primary"
                style="cursor: default"
                tile
                x-large
              >
                Documenten
              </v-btn>
            </p>
            <p
              v-for="index in documenten.length"
              class="pListItem"
              :class="index === 0 ? '' : 'mb-0'"
              :key="index"
            >
              <a
                class="secondaryLink"
                @click="documenten[index - 1].bestand ? OpenLocalFile(documenten[index - 1]) : DownloadFile(documenten[index - 1])"
              >
                {{ documenten[index - 1].naam }}
              </a>
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import blobMixin from '@/mixins/blobMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import blobService from '@/services/blobService'

export default {
  name: 'Rouwdrukwerk',
  mixins: [
    authenticationMixin,
    blobMixin,
    commonMixin,
    constantMixin,
    errorMixin
  ],
  data () {
    return {
      documenten: [
        {
          naam: 'Dichtenbundel',
          bestand: 'dichtenbundel.pdf'
        },
        {
          naam: 'Hoofdingen rouwbrief',
          bestand: 'hoofdingen rouwbrief.pdf'
        },
        {
          naam: 'Rouwbrief',
          subtype: 'rouwbriefOnline'
        }
      ]
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_CheckIfLoggedIn()
    blobService.Init(this)
    this.$store.state.headers.samenstellen = true
  },
  beforeDestroy () {
    this.$store.state.headers.samenstellen = false
  },
  methods: {
    async DownloadFile (item) {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await blobService.DownloadByBlobSearchCriteria({
        subtype: this.constantMixin.codes.rouwdrukBestandstype.values[item.subtype],
        type: this.constantMixin.codes.blobType.values.rouwdrukwerk
      })

      if (resp && (resp.data || resp.data !== '')) {
        this.BlobMixin_DownloadAction(item.naam, null, resp.data)
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    OpenLocalFile (item) {
      window.open(require(`@/assets/documents/${this.$options.name} ${item.bestand}`), '_blank')
    }
  }
}
</script>
