<template>
  <v-dialog
    v-model="$store.state.blobDialog.show"
    content-class="blobDialog"
  >
    <vue-pdf-embed
      :source="$store.state.blobDialog.source"
    />
  </v-dialog>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: 'TheBlobDialog',
  components: {
    VuePdfEmbed
  }
}
</script>

<style>
  @media (min-width: 1280px) {
    .vue-pdf-embed canvas {
      height: unset !important;
      max-height: 830px !important;
      max-width: calc(100%-24px) !important;
      width: unset !important;
    }
  }

  .blobDialog {
    width: unset;
  }
</style>
