<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/${$options.name} banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="6"
      >
        <h1
          class="text-center"
        >
          Meer over Verstappen
        </h1>
        <v-row
          align="center"
          class="my-8 py-8"
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <p>
              Verstappen uitvaartzorg is een volledig onafhankelijk Kempisch familiebedrijf.
              Het ontstond begin de jaren 1960 in Beerse als een klassieke begrafenisonderneming, geleid door een koster-organist.
              Oprichter Jef Verstappen breidde later uit naar Merksplas en vestigde zich in 1980 in Geel, waar hij toen de begrafenisonderneming Tielemans overnam.
              Het stond op dat moment al vast dat zijn neef, Rumo, in zijn voetsporen zou treden.
            </p>
            <p>
              In de voorbije decennia verwierf Verstappen, vertrekkend van twee polen in de Noorder- en de Zuiderkempen, een belangrijke plaats in de sector.
              Vandaag heeft de uitvaartonderneming vestigingen in zes steden en gemeenten tussen Geel en Merksplas.
              De schaalvergroting heeft de persoonlijke aanpak evenwel niet geschaad - daar werd en constant over gewaakt.
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <figure
              v-if="false"
              class="ml-10"
            >
              <v-img
                src="@/assets/images/OverOns Jef Verstappen.jpg"
              />
              <figcaption
                class="font-italic mt-3 text-caption"
              >
                Jef Verstappen met corbeillard anno 1975.
              </figcaption>
            </figure>
          </v-col>
        </v-row>
        <v-row
          align="center"
          class="my-8 py-8"
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <figure
              v-if="false"
              class="mr-10"
            >
              <v-img
                src="@/assets/images/OverOns Rumo Christel Michiel.jpg"
              />
              <figcaption
                class="font-italic mt-3 text-caption"
              >
                Rumo, Christel en Michiel met hun team.
              </figcaption>
            </figure>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <p>
              Rumo en Christel Verstappen geven vandaag leiding aan een uitgebreid team.
              Stuk voor stuk gespecialiseerde mensen met het hart op de juiste plaats.
              Met hun zoon Michiel is ook de volgende generatie in de uitvaartzorg actief.
            </p>
            <p>
              De verantwoordelijkheid voor de funeraria van Merksplas, Beerse en Turnhout is in goede handen bij Steven en Lieve Verstappen, terwijl Ingrid en Tim Wuyts - neef en tante! - Verstappen vertegenwoordigen in Lichtaart en Kasterlee.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OverOns',
  mounted () {
    this.$vuetify.goTo(0)
  }
}
</script>
