<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/${$options.name} banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="6"
      >
        <h1
          class="text-center"
        >
          Extra diensten
        </h1>
        <p
          class="text-center"
        >
          Na een overlijden dienen de nabestaanden vaak heel wat te regelen.
          <br>
          Misschien wil u hen hierbij helpen of hiervan ontlasten bij uw overlijden.
          <br>
          Verstappen uitvaartzorg wil ook hierbij ondersteunen.
        </p>
        <v-row
          align="center"
          class="my-8 py-8"
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <div
              class="mx-7"
            >
              <div
                class="clickAction text-center text-uppercase"
                @click="$router.push('BereidJeUitvaartVoor')"
              >
                <v-img
                  :src="require(`@/assets/images/ExtraDiensten BereidJeUitvaartVoor.jpg`)"
                />
                <div
                  class="imageBottomOverlayText py-5"
                >
                  Uw uitvaart voorbereiden
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <div
              class="mx-7"
            >
              <div
                class="clickAction text-center text-uppercase"
                @click="$router.push('Uitvaartverzekering')"
              >
                <v-img
                  :src="require(`@/assets/images/ExtraDiensten Uitvaartverzekering.jpg`)"
                />
                <div
                  class="imageBottomOverlayText py-5"
                >
                  Uitvaartverzekering
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ExtraDiensten',
  mounted () {
    this.$vuetify.goTo(0)
  }
}
</script>
