<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="10"
      >
        <h1
          class="text-center"
        >
          Uw persoonlijke account
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="12"
          >
            <p
              class="text-center"
            >
              Welkom op uw persoonlijke account.
              <br>
              Op deze manier willen we u extra begeleiden en ondersteunen.
              <br>
              We informeren u over de te nemen stappen bij het samenstellen van de uitvaart.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="6"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="4"
          >
            <v-btn
              block
              color="primary"
              tile
              x-large
              to="Condoleances"
            >
              Condoleances
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-btn
              block
              color="primary"
              tile
              x-large
              to="Koffietafel"
            >
              Koffietafel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-btn
              block
              color="primary"
              tile
              x-large
              to="Muziek"
            >
              Muziek
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="4"
          >
            <v-btn
              block
              color="primary"
              tile
              x-large
              to="rouwdrukwerk"
            >
              rouwdrukwerk
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-btn
              block
              color="primary"
              tile
              x-large
              to="Administratie"
            >
              Administratie
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-btn
              block
              color="primary"
              tile
              x-large
              to="Enquete"
            >
              Mijn mening
            </v-btn>
          </v-col>
          <!-- <v-col
            cols="12"
            lg="4"
          >
            <p>
              <v-btn
                block
                color="primary"
                tile
                x-large
                to="Uitvaart"
              >
                Uitvaart
              </v-btn>
            </p>
            <p>
              Bekijk ontvangen condoleances.
              <br>
              Kies persoonlijke muziek en teksten. Bezorg ons de mooiste foto's.
              <br>
              We zorgen samen met u voor een unieke, persoonlijke en stijlvolle uitvaart.
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <p>
              <v-btn
                block
                color="primary"
                tile
                x-large
                to="Administratie"
              >
                Administratie
              </v-btn>
            </p>
            <p>
              Alle documenten voor de administratieve afhandelingen op één plaats.
              We stellen u een uitgebreide uitvaartbrochure ter beschikking waarin we u stap voor stap informeren wat te doen bij overlijden.
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <p>
              <v-btn
                block
                color="primary"
                tile
                x-large
                to="Overzicht"
              >
                Mijn overzicht
              </v-btn>
            </p>
            <p
              class="mb-0"
            >
              Een duidelijk overzicht van de door u gekozen muziek, teksten en rouwdrukwerk.
            </p>
            <p>
              Volg eenvoudig uw bestellingen van drukwerk op en plaats eventueel extra bestellingen.
            </p>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'

export default {
  name: 'Account',
  mixins: [
    authenticationMixin
  ],
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_CheckIfLoggedIn()
  }
}
</script>
