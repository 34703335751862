<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
    </v-row>
    <v-row
      class="alternatingContentRow my-8 py-8"
    >
      <v-col
        class="center"
        cols="12"
        lg="10"
      >
        <h1
          class="text-center"
        >
          Administratie
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="12"
          >
            <p
              class="text-center"
            >
              Alle belangrijke administratieve documenten op één plaats.
              <br>
              We adviseren u graag via onze uitgebreide informatiebrochure.
              <br>
              Hiervoor heeft u Acrobat Reader nodig.
              U kan dit gratis <a class="secondaryLink" href="https://get.adobe.com/nl/reader/" target="_blank">downloaden</a>.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="center mt-5"
        cols="12"
        lg="6"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="4"
          >
            <p>
              <v-btn
                block
                color="primary"
                style="cursor: default"
                tile
                x-large
                @click="OpenInformatiebrochure()"
              >
                Informatiebrochure
              </v-btn>
            </p>
            <p>
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <v-btn
                    class="mb-1"
                    block
                    color="primary"
                    tile
                    small
                    @click="OpenInformatiebrochure()"
                  >
                    Lezen
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  lg="8"
                >
                  <v-img
                    class="pl-1"
                    :src="require(`@/assets/images/${$options.name} informatiebrochure thumbnail.jpg`)"
                  />
                </v-col>
              </v-row>
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <p>
              <v-btn
                block
                color="primary"
                style="cursor: default"
                tile
                x-large
              >
                Documenten / attesten
              </v-btn>
            </p>
            <p
              v-for="index in documentenAttesten.length"
              class="pListItem"
              :class="index === 1 ? 'mb-0' : ''"
              :key="index"
            >
              <a
                class="secondaryLink"
                @click="DownloadFile(documentenAttesten[index - 1])"
              >
                {{ documentenAttesten[index - 1].naam }}
              </a>
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <p>
              <v-btn
                block
                color="primary"
                style="cursor: default"
                tile
                x-large
              >
                Verzekeringen
              </v-btn>
            </p>
            <p
              v-for="index in verzekeringen.length"
              class="pListItem"
              :class="index === 1 ? 'mb-0' : ''"
              :key="index"
            >
              <a
                class="secondaryLink"
                :href="verzekeringen[index - 1].url"
                target="_blank"
              >
                {{ verzekeringen[index - 1].naam }}
              </a>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import blobMixin from '@/mixins/blobMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import blobService from '@/services/blobService'

export default {
  name: 'Administratie',
  mixins: [
    authenticationMixin,
    blobMixin,
    commonMixin,
    constantMixin,
    errorMixin
  ],
  data () {
    return {
      documentenAttesten: [
        {
          naam: 'Bewijs van kort verlet',
          subtype: 'bewijsVanKortVerlet'
        },
        {
          naam: 'Uittreksel overlijden',
          subtype: 'uittrekselVanOverlijden'
        }
      ],
      verzekeringen: [
        {
          naam: 'Uitvaartverzekering',
          url: 'https://services.corona.be/UKP/BO/?Language=NL&ref=VERSTAP_BF&name=Verstappen%20Uitvaartzorg%20%28Geel%29'
        }
      ]
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
    this.AuthenticationMixin_CheckIfLoggedIn()
  },
  methods: {
    async DownloadFile (item) {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await blobService.DownloadByBlobSearchCriteria({
        subtype: this.constantMixin.codes.facturatieEnDocBestandstype.values[item.subtype],
        type: this.constantMixin.codes.blobType.values.facturatie
      })

      if (resp && (resp.data || resp.data !== '')) {
        this.BlobMixin_DownloadAction(item.naam, null, resp.data)
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    OpenInformatiebrochure () {
      window.open(require(`@/assets/documents/${this.$options.name} informatiebrochure.pdf`), '_blank')
    }
  }
}
</script>
